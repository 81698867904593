import React from 'react';
import HeaderWellington from '../../components/Header/Wellington/HeaderWellington';
import ScheduleListWellington from '../../components/Schedule/Wellington/ScheduleListWellington';
import RoomListWellington from '../../components/Room/Wellington/RoomListWellington';
import { ScheduleContext } from '../../context/ScheduleContext';
import { useEffect, useContext } from 'react';

export default function WellingtonList() {
    const { fetchSchedulesWellington, fetchAds } = useContext(ScheduleContext);

    useEffect(() => {
        const interval = setInterval(() => {
            fetchSchedulesWellington();
            fetchAds();
        }, 30000)

        return () => clearInterval(interval);
    });

    return (
        <>
            <HeaderWellington />
            <ScheduleListWellington />
            <RoomListWellington />
        </>
    )
}