import React from 'react';
import Header from '../components/Header/Header';
import ScheduleList from '../components/Schedule/ScheduleList';
import RoomList from '../components/Room/RoomList';
import Jumbotron from '../components/Jumbotron/Jumbotron'
import { useEffect, useContext } from 'react';
import { ScheduleContext } from '../context/ScheduleContext';

export default function EssexSchedule() {
    const { fetchSchedules, fetchJumbotron } = useContext(ScheduleContext);

    useEffect(() => {
        const interval = setInterval(() => {
            fetchSchedules();
            fetchJumbotron();
        }, 30000)

        return () => clearInterval(interval);
    });

    return(
        <>
            <Header />
            <ScheduleList />
            <RoomList />
            <Jumbotron />
        </>
    );
}