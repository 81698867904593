import React, { useEffect, useContext } from 'react';
import { ScheduleContext } from '../../context/ScheduleContext';
import HeaderWestEnd from '../../components/Header/Guelph/HeaderWestEnd';
import JumbotronWestEnd from '../../components/Jumbotron/Guelph/JumbotronWestEnd';
import WestEndFull2 from '../../components/Schedule/Guelph/WestEndFull2';

export default function WestEndFull2Template () {
    const { fetchSchedulesGuelph, fetchJumbotronGuelph } = useContext(ScheduleContext);

    useEffect(() => {
        const interval = setInterval(() => {
            fetchSchedulesGuelph();
            fetchJumbotronGuelph();
        }, 30000)

        return () => clearInterval(interval);
    });

    return (
        <>
            <HeaderWestEnd />
            <WestEndFull2 />
            <JumbotronWestEnd />
        </>
    )
}