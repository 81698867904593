import React, { useEffect, useContext } from 'react';
import { ScheduleContext } from '../../context/ScheduleContext';
import HeaderIPSC from '../../components/Header/Whitby/HeaderIPSC';
import IPSCArena2Full from '../../components/Schedule/Whitby/IPSCArena2Full';

export default function IPSCArena2() {
    const { fetchSchedulesWhitby, fetchJumbotronWhitby } = useContext(ScheduleContext);

    useEffect(() => {
        const interval = setInterval(() => {
            fetchSchedulesWhitby();
            fetchJumbotronWhitby();
        }, 30000)

        return () => clearInterval(interval);
    });

    return (
        <>
            <HeaderIPSC />
            <IPSCArena2Full />
        </>
    )
}