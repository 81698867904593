import React, { useEffect, useContext } from 'react';
import { ScheduleContext } from '../../context/ScheduleContext';
import HeaderIPSCLogo from '../../components/Header/Whitby/HeaderIPSCLogo';
import IPSCArena3And6Full from '../../components/Schedule/Whitby/IPSCArena3And6Full';


export default function IPSCArena3And6() {
    const { fetchSchedulesWhitby, fetchJumbotronWhitby } = useContext(ScheduleContext);

    useEffect(() => {
        const interval = setInterval(() => {
            fetchSchedulesWhitby();
            fetchJumbotronWhitby();
        }, 30000)

        return () => clearInterval(interval);
    });
    
    return (
        <>
            <HeaderIPSCLogo />
            <IPSCArena3And6Full />
        </>
    )
}