import React, { useEffect, useContext } from 'react';
import { ScheduleContext } from '../context/ScheduleContext';
import ScheduleListHarrow from '../components/Schedule/ScheduleListHarrow';
import RoomListHarrow from '../components/Room/RoomListHarrow';
import HeaderHarrow from '../components/Header/HeaderHarrow';
import JumbotronHarrow from '../components/Jumbotron/JumbotronHarrow';

export default function HarrowSchedule() {
    const { fetchSchedulesHarrow, fetchJumbotronHarrow } = useContext(ScheduleContext);

    useEffect(() => {
        const interval = setInterval(() => {
            fetchSchedulesHarrow();
            fetchJumbotronHarrow();
        }, 30000)

        return () => clearInterval(interval);
    }, []);

    return(
        <>
            <HeaderHarrow />
            <ScheduleListHarrow />
            <RoomListHarrow />
            <JumbotronHarrow />
        </>
    );
}