import React, { useEffect, useContext } from 'react';
import { ScheduleContext } from '../../context/ScheduleContext';
import Field from '../../components/Schedule/WestHants/Field';

export default function WestHantsField () {
    const { fetchSchedulesWestHants, fetchAdsWestHants } = useContext(ScheduleContext);

    useEffect(() => {
        const interval = setInterval(() => {
            fetchSchedulesWestHants();
            fetchAdsWestHants();
        }, 30000)

        return () => clearInterval(interval);
    });

    return (
        <Field />
    )
}