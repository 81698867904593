import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import AuthContext from '../../context/AuthContext';
import HeaderWellingtonEdit from '../../components/Header/Wellington/HeaderWellingtonEdit';
import Teams from '../../components/Teams/Wellington/Teams';
import LoginWellington from '../../components/Auth/Wellington/LoginWellington'
import ScheduleEditWellington from '../../components/Schedule/Wellington/ScheduleEditWellington';
import ManageAndUploadAds from '../../components/Ads/UploadAds';


export default function WellingtonEdit() {
    const { user, client, logout } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (user && client !== 'wellington') {
            alert('You do not have access to this page.');
            logout(navigate);
        }
    }, [user, client, logout, navigate]);

    if (!user) {
        return <LoginWellington />;
    }

    if (client !== 'wellington') {
        return null;
    }

    return (
        <>
            <HeaderWellingtonEdit />
            <br></br>
            <ScheduleEditWellington />
            <br></br>
            <ManageAndUploadAds />
            <br></br>
            <Teams />
            <footer className="bg-dark text-white text-center py-4">
                <div className="container">
                <p>&copy; 2024 Arena Scheduling. All Rights Reserved.</p>
                </div>
            </footer>
        </>
    )
}