import React from 'react';
import Header from '../components/Header/Header';
import ScheduleFull from '../components/Schedule/ScheduleFull';
import Jumbotron from '../components/Jumbotron/Jumbotron';
import { ScheduleContext } from '../context/ScheduleContext';
import { useEffect, useContext } from 'react';

export default function EssexScheduleFull() {
    const { fetchSchedules, fetchJumbotron } = useContext(ScheduleContext);

    useEffect(() => {
        const interval = setInterval(() => {
            fetchSchedules();
            fetchJumbotron();
        }, 30000)

        return () => clearInterval(interval);
    }, [fetchSchedules, fetchJumbotron]);

    return(
        <>
            <Header />
            <ScheduleFull />
            <Jumbotron />
        </>
    );
}