import React, { createContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [client, setClient] = useState(null);

  const CLOUD_RUN_URL = 'https://arenascheduling.uc.r.appspot.com';

  const login = async (username, password, clientName, navigate) => {
    const clientNameCapitalized = clientName.charAt(0).toUpperCase() + clientName.slice(1);
    
    if (!username.includes(clientNameCapitalized)) {
      alert(`Invalid username for ${clientName}.`);
      return;
    }

    try {
      const response = await fetch(`${CLOUD_RUN_URL}/api/login/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });
      const data = await response.json();
      if (response.ok) {
        setUser(data.user_id);
        setClient(clientName);
        localStorage.setItem('user', JSON.stringify(data.user_id));
        localStorage.setItem('client', clientName);
        navigate(`/${clientName}/edit`);
      } else {
        throw new Error(data.error);
      }
    } catch (error) {
      console.error('Login failed:', error);
      alert('Login failed: ' + error.message);
    }
  };

  const loginEssex = (username, password, navigate) => login(username, password, 'essex', navigate);
  const loginWellington = (username, password, navigate) => login(username, password, 'wellington', navigate);
  const loginGuelph = (username, password, navigate) => login(username, password, 'guelph', navigate);
  const loginWestHants = (username, password, navigate) => login(username, password, 'westhants', navigate);
  const loginWhitby = (username, password, navigate) => login(username, password, 'whitby', navigate);

  const logout = (navigate) => {
    setUser(null);
    setClient(null);
    localStorage.removeItem('user');
    localStorage.removeItem('client');
    navigate('/');
  };

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    const storedClient = localStorage.getItem('client');
    if (storedUser && storedClient) {
      setUser(JSON.parse(storedUser));
      setClient(storedClient);
    }
  }, []);

  return (
    <AuthContext.Provider value={{ user, client, loginEssex, loginWellington, loginGuelph, loginWestHants, loginWhitby, logout, login }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
