import React, { useContext, useEffect, useState } from 'react';
import { ScheduleContext } from '../../../context/ScheduleContext'; // Import the context
import VenueSchedule from './VenueSchedule';
import '../Schedule.css';
import Ads from '../../Ads/WestHants/Ads';

export default function Field () {
    const { schedulesWestHants, teamsWestHants } = useContext(ScheduleContext); // Use context to get schedules and teams
    const [fieldSchedules, setFieldSchedules] = useState([]);
    const [teamMap, setTeamMap] = useState({});

    useEffect(() => {
        const today = new Date().toISOString().split('T')[0];

        // Create a map of team codes to team names
        const teamMapping = teamsWestHants.reduce((map, team) => {
        map[team.Code] = team.Name;
        return map;
        }, {});
        setTeamMap(teamMapping);

        const field = ensureNEntries(schedulesWestHants.filter(s => s.Venue === 'West Hants Field 1' && s.Date === today));
        setFieldSchedules(field);
    }, [schedulesWestHants, teamsWestHants]); // Rerender when schedules or teams context changes

    function ensureNEntries(schedules) {
        const now = new Date();
      
        // Sort schedules by Date and StartTime
        schedules.sort((a, b) => {
          const dateA = new Date(`${a.Date}T${a.StartTime}:00`);
          const dateB = new Date(`${b.Date}T${b.StartTime}:00`);
          return dateA - dateB;
        });
      
        // Filter out past events (events that ended before now)
        const upcomingSchedules = schedules.filter(s => {
          const endTime = new Date(`${s.Date}T${s.EndTime}:00`);
          return endTime >= now;
        });
      
        // Limit to the next 5 events
        const limitedSchedules = upcomingSchedules.slice(0, 10);
      
        // Add placeholders if less than 5 events are found
        const placeholder = { StartTime: '', EndTime: '', Team: '', HomeRoom: '', Opponent: '', AwayRoom: '', Venue: '' };
        while (limitedSchedules.length < 10) {
          limitedSchedules.push(placeholder);
        }
      
        return limitedSchedules;
      }

    return (
        <div className="container-fluid d-flex flex-column mt-5">
            <div className="row flex-grow-1">
                <div className="col-md-8">
                    <Ads />
                </div>
                <div className="col-md-4 d-flex align-items-start justify-content-end">
                    <VenueSchedule venueName="Field" schedules={fieldSchedules} teamMap={teamMap} />
                </div>
            </div>
        </div>
    );
}