import React, { useEffect, useContext } from 'react';
import HeaderExhibition from '../../components/Header/Guelph/HeaderExhibition';
import ExhibitionFull from '../../components/Schedule/Guelph/ExhibitionFull';
import JumbotronExhibition from '../../components/Jumbotron/Guelph/JumbotronExhibition';
import { ScheduleContext } from '../../context/ScheduleContext';

export default function ExhibitionFullTemplate () {
    const { fetchSchedulesGuelph, fetchJumbotronGuelph } = useContext(ScheduleContext);

    useEffect(() => {
        const interval = setInterval(() => {
            fetchSchedulesGuelph();
            fetchJumbotronGuelph();
        }, 30000)

        return () => clearInterval(interval);
    });
    return (
        <>
        <HeaderExhibition />
        <ExhibitionFull />
        <JumbotronExhibition />
        </>
    )
}