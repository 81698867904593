import React from 'react';
import ScheduleListElora from '../../components/Schedule/Wellington/ScheduleListElora';
import RoomListElora from '../../components/Room/Wellington/RoomListElora';
import HeaderElora from '../../components/Header/Wellington/HeaderElora';
import { ScheduleContext } from '../../context/ScheduleContext';
import { useEffect, useContext } from 'react';

export default function EloraList() {
    const { fetchSchedulesWellington, fetchAds } = useContext(ScheduleContext);

    useEffect(() => {
        const interval = setInterval(() => {
            fetchSchedulesWellington();
            fetchAds();
        }, 30000)

        return () => clearInterval(interval);
    });

    return (
        <>
            <HeaderElora />
            <ScheduleListElora />
            <RoomListElora />
        </>
    )
}