import React, { useEffect, useContext } from 'react';
import { ScheduleContext } from '../../context/ScheduleContext';
import HeaderLuther from '../../components/Header/Whitby/HeaderLuther';
import LutherArenaFull from '../../components/Schedule/Whitby/LutherArenaFull';

export default function LutherArena() {
    const { fetchSchedulesWhitby, fetchJumbotronWhitby } = useContext(ScheduleContext);

    useEffect(() => {
        const interval = setInterval(() => {
            fetchSchedulesWhitby();
            fetchJumbotronWhitby();
        }, 30000)

        return () => clearInterval(interval);
    });
    
    return (
        <>
            <HeaderLuther />
            <LutherArenaFull />
        </>
    )
}