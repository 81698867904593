import React, { useEffect, useContext } from 'react';
import { ScheduleContext } from '../../context/ScheduleContext';
import MCKArena1Full from '../../components/Schedule/Whitby/MCKArena1Full';
import HeaderIPSCLogo from '../../components/Header/Whitby/HeaderIPSCLogo';

export default function MCKArena1() {
    const { fetchSchedulesWhitby, fetchJumbotronWhitby } = useContext(ScheduleContext);

    useEffect(() => {
        const interval = setInterval(() => {
            fetchSchedulesWhitby();
            fetchJumbotronWhitby();
        }, 30000)

        return () => clearInterval(interval);
    });
    
    return (
        <>
            <HeaderIPSCLogo />
            <MCKArena1Full />
        </>
    )
}