import React, { useState, useEffect, useContext } from 'react';
import { ScheduleContext } from '../../../context/ScheduleContext';
import RecurringEventModal from '../RecurringEventModal';
import '../Schedule.css'

export default function VenueScheduleEditWellington({ venueName }) {
  const { schedulesWellington, teams, saveSchedulesWellington, selectedDateWellington, addRecurringEventWellington, deleteScheduleWellington } = useContext(ScheduleContext);
  const [editableSchedules, setEditableSchedules] = useState([]);
  const [saving, setSaving] = useState(false);
  const [saveError, setSaveError] = useState(null);
  const [teamList, setTeamList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(true);

  // Add these new state variables
  const [roomPatterns, setRoomPatterns] = useState([['1', '3'], ['2', '4']]);
  const [showRoomConfig, setShowRoomConfig] = useState(false);

  // Add this function to sort schedules
  const sortSchedulesByTime = (schedules) => {
    return schedules.sort((a, b) => {
      if (a.StartTime < b.StartTime) return -1;
      if (a.StartTime > b.StartTime) return 1;
      return 0;
    });
  };

  useEffect(() => {
    const dateStr = selectedDateWellington.toISOString().split('T')[0];
    
    const filteredSchedules = schedulesWellington.filter(s => s.Venue === venueName && s.Date === dateStr);
    setEditableSchedules(sortSchedulesByTime(filteredSchedules));
  }, [schedulesWellington, venueName, selectedDateWellington]);

  useEffect(() => {
    if (teams) {
      const loadedTeams = Object.keys(teams).map(key => ({
        Id: key,
        Code: teams[key].Code,
        Name: teams[key].Name
      }));
      setTeamList(loadedTeams);
    }
  }, [teams]);

  const addNewRow = () => {
    setEditableSchedules(prev => [
      ...prev, 
      { Date: selectedDateWellington.toISOString().split('T')[0], StartTime: '', EndTime: '', Type: '', Team: '', HomeRoom: '', Opponent: '', AwayRoom: '', Id: null }
    ]);
  };

  const handleScheduleChange = (index, field, value) => {
    const updatedSchedules = editableSchedules.map((schedule, idx) => {
      if (idx === index) {
        return { ...schedule, [field]: value, Venue: venueName };
      }
      return schedule;
    });
    setEditableSchedules(updatedSchedules);
  };

  const handleDelete = async (scheduleId) => {
    if (window.confirm('Are you sure you want to delete this schedule?')) {
      try {
        await deleteScheduleWellington(venueName, scheduleId);
        // Remove the deleted schedule from editableSchedules
        setEditableSchedules(prev => prev.filter(schedule => schedule.Id !== scheduleId));
        alert('Schedule deleted successfully');
      } catch (error) {
        console.error('Error deleting schedule:', error);
        setSaveError('Error deleting schedule:', error);
      }
    }
  };

  const handleSaveSchedules = async () => {
    setSaving(true);
    setSaveError(null);
    try {
      const validSchedules = editableSchedules.filter(schedule => schedule.Date && schedule.StartTime && schedule.EndTime);

      if (validSchedules.length > 0) {
        await saveSchedulesWellington(venueName, validSchedules);
        alert("Schedules saved successfully. Please refresh to view changes");
      } else {
        console.error('No valid schedules to save.');
        setSaveError('No valid schedules to save.');
      }
    } catch (error) {
      console.error('Error saving schedules:', error);
      setSaveError(`Error: ${error.message}`);
    }
    setSaving(false);
  };

  const handleModalShow = () => setShowModal(true);
  const handleModalClose = () => setShowModal(false);

  const handleModalSubmit = async (formData) => {
    setSaveError(null);
    setSaving(true);
    try {
      console.log('Recurring Event Data:', formData);
      await addRecurringEventWellington(formData);
      handleModalClose();
    } catch (error) {
      console.error('Error adding recurring event:', error);
      setSaveError('Error adding recurring event:', error)
    }
    setSaving(false);
    alert("Recurring event added. Please refresh to view changes")
  };

  // Add these new functions
  const autoIncrementRooms = () => {
    let patternIndex = 0;

    const updatedSchedules = editableSchedules.map(schedule => {
      const updatedSchedule = { ...schedule };
      if (!schedule.HomeRoom || !schedule.AwayRoom) {
        [updatedSchedule.HomeRoom, updatedSchedule.AwayRoom] = roomPatterns[patternIndex];
        patternIndex = (patternIndex + 1) % roomPatterns.length;
      }
      return updatedSchedule;
    });

    setEditableSchedules(updatedSchedules);
  };

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleRoomPatternChange = (index, room, value) => {
    const updatedPatterns = [...roomPatterns];
    updatedPatterns[index][room] = value;
    setRoomPatterns(updatedPatterns);
  };

  const addRoomPattern = () => {
    setRoomPatterns([...roomPatterns, ['', '']]);
  };

  const removeRoomPattern = (index) => {
    const updatedPatterns = roomPatterns.filter((_, i) => i !== index);
    setRoomPatterns(updatedPatterns);
  };

  return (
    <div className='container-fluid'>
      <div className="col mb-4">
        <div className="card h-100">
          <div className="h2 btn btn-primary btn-lg card-header text-light" onClick={toggleCollapse} style={{ cursor: 'pointer' }}>
            {venueName}
          </div>
          <div className={`collapse ${!isCollapsed ? 'show' : ''}`}>
            <div className="card-body">
              <RecurringEventModal
                show={showModal}
                handleClose={handleModalClose}
                handleSubmit={handleModalSubmit}
                venueName={venueName}
              />
              {saving && <p>Saving schedules...</p>}
              {saveError && <p>{saveError}</p>}
              <div className="table-responsive">
                <table className="table table">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Start Time</th>
                      <th>End Time</th>
                      <th>Home Team</th>
                      <th>Home Room</th>
                      <th>Away Team</th>
                      <th>Away Room</th>
                      <th>Type</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {editableSchedules.map((schedule, index) => (
                      <tr key={schedule.Id || index}>
                        <td className="col-12 col-md-2 mb-2">
                          <input type="date" className="form-control" value={schedule.Date || ''} onChange={(e) => handleScheduleChange(index, 'Date', e.target.value)} />
                        </td>
                        <td className="col-12 col-md-2 mb-2">
                          <input type="time" className="form-control" value={schedule.StartTime || ''} onChange={(e) => handleScheduleChange(index, 'StartTime', e.target.value)} />
                        </td>
                        <td className="col-12 col-md-2 mb-2">
                          <input type="time" className="form-control" value={schedule.EndTime || ''} onChange={(e) => handleScheduleChange(index, 'EndTime', e.target.value)} />
                        </td>
                        <td className="col-12 col-md-2 mb-2">
                          <input list="team-names" className="form-control" value={schedule.Team || ''} onChange={(e) => handleScheduleChange(index, 'Team', e.target.value)} />
                          <datalist id="team-names">
                            {Array.isArray(teamList) && teamList.map((team) => (
                              <option key={team.Id} value={team.Name} />
                            ))}
                          </datalist>
                        </td>
                        <td className="col-12 col-md-2 mb-2">
                          <input type="text" className="form-control" value={schedule.HomeRoom || ''} onChange={(e) => handleScheduleChange(index, 'HomeRoom', e.target.value)} />
                        </td>
                        <td className="col-12 col-md-2 mb-2">
                          <input list="team-names" className="form-control" value={schedule.Opponent || ''} onChange={(e) => handleScheduleChange(index, 'Opponent', e.target.value)} />
                          <datalist id="team-names">
                            {Array.isArray(teamList) && teamList.map((team) => (
                              <option key={team.Id} value={team.Name} />
                            ))}
                          </datalist>
                        </td>
                        <td className="col-12 col-md-2 mb-2">
                          <input type="text" className="form-control" value={schedule.AwayRoom || ''} onChange={(e) => handleScheduleChange(index, 'AwayRoom', e.target.value)} />
                        </td>
                        <td className="col-12 col-md-2 mb-2">
                          <input type="text" className="form-control" value={schedule.Type || ''} onChange={(e) => handleScheduleChange(index, 'Type', e.target.value)} />
                        </td>
                        <td className="col-12 col-md-2 mb-2">
                          <button 
                            className="btn btn-danger"
                            onClick={() => handleDelete(schedule.Id)}
                            disabled={!schedule.Id}
                          >
                            <span className="material-symbols-outlined">delete</span>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <button className="btn btn-primary" onClick={handleSaveSchedules}>Save Changes</button>
                <button className="btn btn-success m-2" onClick={addNewRow}>Add New Event</button>
                <button className="btn btn-success" onClick={handleModalShow}>Add Recurring Events</button>
                {/* Add these new buttons */}
                <button className="btn btn-info m-1" onClick={autoIncrementRooms}>Auto-increment Rooms</button>
                <button className="btn btn-secondary m-1" onClick={() => setShowRoomConfig(!showRoomConfig)}>
                  {showRoomConfig ? 'Hide' : 'Show'} Room Configuration
                </button>
                
                {/* Add this new section for room configuration */}
                {showRoomConfig && (
                  <div className="mt-3">
                    <h4>Room Patterns</h4>
                    {roomPatterns.map((pattern, index) => (
                      <div key={index} className="d-flex mb-2">
                        <input
                          type="text"
                          className="form-control mr-2"
                          value={pattern[0]}
                          onChange={(e) => handleRoomPatternChange(index, 0, e.target.value)}
                          placeholder="Home Room"
                        />
                        <input
                          type="text"
                          className="form-control mr-2"
                          value={pattern[1]}
                          onChange={(e) => handleRoomPatternChange(index, 1, e.target.value)}
                          placeholder="Away Room"
                        />
                        <button className="btn btn-danger" onClick={() => removeRoomPattern(index)}>Remove</button>
                      </div>
                    ))}
                    <button className="btn btn-primary" onClick={addRoomPattern}>Add Pattern</button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
